<template>
  <div class="pybhome">
    <div class="pybhome-img">
      <div class="circle1"></div>
      <div class="circle2"></div>
      <div class="circle3"></div>
      <div class="circle4"></div>
      <div class="circle5"></div>
      <img src="../../assets/images/pyb.png" alt="" />
    </div>
    <div class="pybhome-head">
      <div class="head-left">
        <div class="head-left-top">
          <div><img src="../../assets/images/pyb2.png" alt="" /></div>
          <span>培优币</span>
        </div>
        <div class="pybdata">
          <div class="head-left-bottom">
            <div class="left-bottom-list">
              <span>累计</span>
              <div class="bottom-lits">{{ total }}</div>
            </div>
            <div class="left-bottom-list">
              <span>消耗</span>
              <div class="bottom-lits">{{ used }}</div>
            </div>
            <div class="left-bottom-list">
              <span>剩余</span>
              <div class="bottom-lits">{{ balance }}</div>
            </div>
            <div class="left-bottom-list">
              <span>本周</span>
              <div class="bottom-lits">{{ thisWeek }}</div>
            </div>
          </div>
          <div class="ten-week" round @click="clickWeek">
            <div>过去十周</div>
            <div class="iconfont icon-indicator-full"></div>
          </div>
        </div>
      </div>
      <div class="head-right">
        <div class="head-right-shop" @click="toShop">
          <div class="iconfont icon-jiantouarrowheads3"></div>
          <div>培优商城</div>
        </div>
      </div>
    </div>
    <div class="pybhome-body">
      <el-table :data="history" :stripe="true" height="100%">
        <el-table-column prop="time" label="时间" align="center">
            <template slot-scope="scope">
                {{
                  scope.row.time | dateFormat("YYYY-MM-DD   hh:mm:ss")
                }}
              </template>
        </el-table-column>
        <el-table-column prop="module" label="关卡" align="center">
        </el-table-column>
        <el-table-column prop="task" label="任务" align="center">
        </el-table-column>
        <el-table-column prop="amount" label="斩获培优币" align="center">
        </el-table-column>
      </el-table>
    </div>
    <div v-show="isShow">
      <div id="echartsDemo" class="click-week"></div>
      <div class="click-close" @click="closeWeek">×</div>
    </div>
  </div>
</template>

<script>
import { getRewardsOverviewForView, getWeeksRewards } from "@/api/index";
export default {
  name: "PybHome",
  components: {},
  data() {
    return {
      history: [],
      isShow: false,
      total: 0,
      used: 0,
      balance: 0,
      thisWeek: 0,
      xData: [],
      sriersData: [],
    };
  },
  mounted() {
    this.getPybOverview();
  },
  methods: {
    getPybHist() {
      getWeeksRewards({}).then((res) => {
        for (let val of res.data.result) {
          this.xData.push(val.time);
          this.sriersData.push(val.amount);
          console.log("培优币过去十周meixiang", this.sriersData);
        }
        this.echartsInit();
      });
    },
    getPybOverview() {
      getRewardsOverviewForView({}).then((res) => {
        ["total", "used", "balance", "history", "thisWeek"].forEach((key) => {
          if (key in res.data.result) {
            this[key] = res.data.result[key];
          }
        });
      });
    },
    closeWeek() {
      this.isShow = false;
    },
    // 点击培优币商城按钮，进入培优商城页面
    toShop() {
      this.$emit("toshop");
    },
    clickWeek() {
      this.isShow = !this.isShow;
      this.$nextTick(() => {
        this.getPybHist();
      });
    },
    echartsInit() {
      // 引入 ECharts 主模块
      var echarts = require("echarts");

      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById("echartsDemo"));
      // 绘制图表
      myChart.setOption({
        title: {
          text: "过去十周",
          x: "center",
          y: "7px",
          textStyle: {
            color: "#fff",
          },
        },
        tooltip: {},
        xAxis: {
          name: "时间",
          data: [
            "前9周",
            "前8周",
            "前7周",
            "前6周",
            "前5周",
            "前4周",
            "前3周",
            "前2周",
            "前1周",
            "本周",
          ],
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
        },
        yAxis: {
          name: "培优币",
          axisLine: {
            lineStyle: {
              color: "#fff",
            },
          },
        },
        series: [
          {
            name: "",
            type: "bar",
            data: this.sriersData,
            color: ["#FECC42"],
            itemStyle: {
              normal: {
                label: {
                  show: true, //开启显示
                  position: "top", //在上方显示
                  textStyle: {
                    //数值样式
                    color: "#fff",
                    fontSize: 16,
                  },
                },
              },
            },
          },
        ],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.pybhome {
  height: 550px;
  width: 100%;
  position: relative;
}
.pybdata {
  display: flex;
}
.pybhome-img {
  display: flex;
  align-items: center;
  img {
    position: absolute;
    top: -12%;
    left: 40%;
  }
  .circle1,
  .circle2,
  .circle3,
  .circle4,
  .circle5 {
    width: 100px;
    height: 50px;
    border-radius: 50px 50px 0 0;
    line-height: 50px;
    background-color: #2cb774;
    opacity: 0.73;
    position: absolute;
  }
  .circle1 {
    top: -30px;
    left: 24%;
  }
  .circle2 {
    top: -25px;
    left: 33%;
  }
  .circle3 {
    top: -22px;
    left: 51%;
  }
  .circle4 {
    top: -40px;
    left: 75%;
  }
  .circle5 {
    background: #fecc42;
    top: -50px;
    left: 82%;
  }
}

.pybhome-head {
  background-color: #2cb774;
  border-radius: 10px 10px 0 0;
  display: flex;
  padding: 20px 25px;
}
.head-left {
  width: 70%;
  display: flex;
  flex-direction: column;
}
.head-left-top {
  display: flex;
  flex-flow: row nowrap;
  font-size: 20px;
  color: #fff;
  font-weight: bold;
  align-items: flex-start;
  margin: 0 0 20px;
  & > div:nth-child(1) {
    display: flex;
    align-content: flex-end;
    padding-right: 5px;
    img {
      width: 33px;
    }
  }
}
.head-left-bottom {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  color: #fff;
  padding-right: 0px;
  & > div:nth-child(1) {
    padding-left: 0;
  }
}
.left-bottom-list {
  padding: 0 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}
.ten-week {
  display: flex;
  padding: 0px 4px;
  padding-left: 7px;
  border: 1px solid #fff;
  border-radius: 30px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.iconshubiaozhizhen {
  font-size: 16px;
}
.bottom-lits {
  margin-left: 5px;
  font-weight: bold;
}
.head-right {
  width: 30%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.head-right-shop {
  justify-content: center;
  width: 124px;
  height: 31px;
  border: 1px solid #fff;
  border-radius: 30px;
  display: flex;
  flex-flow: row nowrap;
  font-size: 18px;
  color: #fff;
  align-items: center;
  cursor: pointer;
}
.pybhome-body {
  height: 438px;
  background-color: $bg-gray;
  border-radius: 0 0 10px 10px;
}
.click-week {
  height: 380px;
  width: 75%;
  background-color: #2cb774;
  position: absolute;
  left: 115px;
  top: 160px;
  border-radius: 5px;
  color: white;
}
.click-close {
  position: absolute;
  right: 140px;
  top: 170px;
  font-size: 30px;
  color: #fff;
  cursor: pointer;
}
</style>
<style lang="scss">
.shopping-foot .el-image-viewer__wrapper {
  z-index: 2010 !important;
}
.shopping-foot .el-icon-circle-close:before {
  color: #fff;
}
</style>
