<template>
  <div class="home">
    <b-container fluid class="bv-example-row">
      <b-row class="home-top">
        <b-col cols="1" class="home-top-left">
          <div>
            <b-button disabled id="popover-homework">
              <div>
                <div class="iconfont icon-xinbaniconshangchuan-"></div>
                家庭作业
              </div>
            </b-button>
            <b-popover
              target="popover-homework"
              custom-class="home-homework-popover"
              triggers="focus"
            >
              <template #title>
                <div class="operation">
                  <div class="operation-a operation-task">
                    <div>
                      <span class="operation-data">3</span>项
                      <span
                        class="
                          iconfont
                          icon-yunyingpingtaierjicaidanicon_jichuxinxi_cihuizidian
                        "
                      ></span>
                    </div>
                    <div>作业任务</div>
                  </div>
                  <div class="operation-a">
                    <div>
                      <EndCanvas
                        :score="1"
                        :totalScore="2"
                        :canvasId="turnoverRateId"
                        :fillingColor="turnoverRateColor"
                      >
                        <span>12%</span>
                      </EndCanvas>
                    </div>
                    <div>完成率</div>
                  </div>
                  <div class="operation-a">
                    <div>
                      <EndCanvas
                        :score="1"
                        :totalScore="2"
                        :canvasId="scoringRateId"
                        :fillingColor="turnoverRateColor"
                      >
                        <span>12%</span>
                      </EndCanvas>
                    </div>
                    <div>得分率</div>
                  </div>
                  <div class="deadline">
                    <div>15:30</div>
                    <div>2022-01-12</div>
                    <div>截止日期</div>
                  </div>
                </div>
              </template>
            </b-popover>
            <b-button @click="homeContent" id="popover-button-sync">
              <div>
                <div class="iconfont icon-xingzhuangjiehe"></div>
                闯关进度
              </div>
            </b-button>
            <div v-click-outside="closeStandardAnswer">
              <b-popover
                :show.sync="rushShow"
                target="popover-button-sync"
                custom-class=" home-progress"
              >
                <template #title>
                  <div class="progress-data">
                    <div class="progress-a">
                      <div>
                        <EndCanvas
                          :score="
                            sprintProgress.currentProgressRate | turnPercentage
                          "
                          :totalScore="100"
                          :canvasId="progressId"
                          :fillingColor="turnoverRateColor"
                        >
                          <span
                            >{{
                              sprintProgress.currentProgressRate
                                | turnPercentage
                            }}%</span
                          >
                        </EndCanvas>
                      </div>
                      <div>当前关卡进度</div>
                    </div>
                    <div class="progress-a">
                      <div>
                        <span class="week-data">{{
                          sprintProgress.thisWeekAnswerHours
                        }}</span
                        ><span class="week-text">
                          今日{{ sprintProgress.todayAnswerHours }}</span
                        >
                      </div>
                      <div>本周冲刺（小时）</div>
                    </div>

                    <div class="progress-a">
                      <div>
                        <span class="week-data">{{
                          sprintProgress.thisWeekAnswerNumber
                        }}</span
                        ><span class="week-text">
                          今日{{ sprintProgress.todayAnswerNumber }}</span
                        >
                      </div>
                      <div>本周答题（道）</div>
                    </div>
                    <div class="pyb-quantity">
                      <div>
                        <span class="week-data">{{
                          sprintProgress.thisWeekRewards
                        }}</span
                        ><span class="week-text">
                          今日{{ sprintProgress.todayRewards }}</span
                        >
                      </div>
                      <div>本周培优币（枚）</div>
                    </div>
                  </div>
                </template>
              </b-popover>
            </div>

            <b-button @click="weeklyBestSellers" id="popover-list">
              <div>
                <div class="iconfont icon-biaoqiankuozhan_bangdan-348"></div>
                本周榜单
              </div>
            </b-button>
            <b-popover
              target="popover-list"
              custom-class="home-weekly-list"
              triggers="focus"
            >
              <template #title>
                <div class="progress-box">
                  <div class="listTitle">
                    <div>排名</div>
                    <div>班级</div>
                    <div>姓名</div>
                    <div>培优币</div>
                  </div>
                  <div class="classListPyb">
                    <div
                      v-for="(item, index) in studentRankings"
                      :key="index"
                      class="classList"
                    >
                      <div class="classListContent">
                        <div
                          :class="
                            index == 0
                              ? 'classA'
                              : index == 1
                              ? 'classB'
                              : index == 2
                              ? 'classC'
                              : 'yellowBackground'
                          "
                        >
                          {{ index + 1 }}
                        </div>
                        <div class="classListB">
                          {{ studentClass(item.className) }}班
                        </div>
                        <div class="classListC">{{ item.studentName }}</div>
                        <div class="classListD">{{ item.totalRewards }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="personal-information">
                    <!-- <div class="upDownArrow">
                      <span
                        :class="isTop == 2 ? 'arrowGrey' : ''"
                        class="iconfont icon-arrow"
                        @click="changeScroll(true)"
                      ></span>
                      <span
                        :class="isTop == 0 ? 'arrowGrey' : ''"
                        class="iconfont icon-jiantou"
                        @click="changeScroll(false)"
                      ></span>
                    </div> -->
                    <div class="myPyb">
                      <span>我的：</span>
                      <span>{{ peiyoubi }}</span>
                      <span class="iconfont iconw_jinbi"></span>
                    </div>
                  </div>
                </div>
              </template>
            </b-popover>
          </div>
        </b-col>
        <b-col>
          <HomeIndex
            :studenModule="studenModule"
            :currentSprintId="studentLnformation.currentSprintId"
            :name="name"
          >
          </HomeIndex>
          <div v-show="studentLnformation.isAllPass" class="all-pass-img">
            <img src="../assets/images/all-pass-img.png" alt="" />
          </div>
        </b-col>
        <div v-show="studentLnformation.isAllPass" class="all-pass-text">
          <div>恭喜！你已完成中考【冲刺进阶版】的大通关~</div>
          <div>点击选择任意关卡头像，可进入自由模式进行答题训练!</div>
        </div>
      </b-row>
      <b-row class="home-bottom">
        <b-col cols="12" sm="4">
          <b-row>
            <b-col>
              <div>总人数</div>
              <div class="bottom-digital">
                {{ studentLnformation.totalStudentCount }}
              </div>
            </b-col>
            <b-col>
              <div>通关人数</div>
              <div class="bottom-digital">
                {{ studentLnformation.passStudentsCount }}
              </div>
            </b-col>
            <b-col>
              <div>通关占比</div>
              <div class="bottom-digital">
                {{ studentLnformation.passPercents | turnPercentage }}%
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="0"></b-col>
        <b-col cols="12" sm="4" class="home-bottom-countdown">
          <div>中考倒计时</div>
          <div class="home-bottom-time">
            <span>{{ day }}</span
            >天 <span>{{ hour }}</span
            >时 <span>{{ min }}</span
            >分
          </div>
        </b-col>
        <b-col cols="0"></b-col>
        <b-col>
          <b-row>
            <b-col @click="openPyb" class="home-bottom-quantity">
              <span class="iconfont icon-w_jinbi"></span>
              <div>
                培优币
                <div class="bottom-digital pyb-digital">
                  {{
                    studentLnformation.rewardThumb == null
                      ? ""
                      : studentLnformation.rewardThumb.rewardCount
                  }}
                </div>
              </div>
            </b-col>
            <b-col class="home-bottom-quantity">
              <span class="iconfont icon-dianzan"></span>
              <div>
                点赞数
                <div class="bottom-digital">
                  {{
                    studentLnformation.rewardThumb == null
                      ? ""
                      : studentLnformation.rewardThumb.thumbCount
                  }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <el-dialog
          custom-class="pyb-dialog"
          :visible.sync="pybDialog"
          width="50%"
        >
          <Pybshop></Pybshop>
        </el-dialog>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Pybshop from "../components/pyb-dialog/pyb-shop.vue";
import HomeIndex from "./english-home/home-card.vue";
import EndCanvas from "../components/english-end/end-canvas.vue";
import { getEngCurrentModule, getRewardsRank } from "../api/index";
import { mapMutations } from "vuex";
export default {
  name: "Home",
  data() {
    return {
      studentLnformation: "",
      curStartTime: "",
      day: "0",
      hour: "00",
      min: "00",
      studenModule: [],
      turnoverRateId: "turnoverRateId",
      scoringRateId: "scoringRateId",
      turnoverRateColor: "#FFB037",
      progressId: "progressId",
      name: "",
      // divShow: false,
      rushShow: false,
      sprintProgress: "",
      studentRankings: [],
      peiyoubi: "",
      isTop: 0,
      pybDialog: false,
    };
  },
  components: {
    HomeIndex,
    EndCanvas,
    Pybshop,
  },
  mounted() {
    this.homeContent();
  },
  methods: {
    ...mapMutations(["setUserInfor"]),
    openPyb() {
      this.pybDialog = true;
    },
    closeStandardAnswer() {
      this.rushShow = false;
    },
    rushClick(res) {
      this.rushShow = true;
      this.sprintProgress = res;
    },
    homeContent() {
      getEngCurrentModule().then((res) => {
        this.studenModule = res.data.result.engModuleProgressList;
        this.studentLnformation = res.data.result;
        this.curStartTime = res.data.result.timeTillFinal;
        this.name = res.data.result.studentBaseInfos.studentName;
        this.countTime();
        this.setUserInfor(res.data.result.studentBaseInfos);
        // if (this.studentLnformation.isAllPass == true) {
        //   var self = this;
        //   self.divShow = true;
        //   setTimeout(() => {
        //     self.divShow = false;
        //   }, 10000);
        // }
        this.rushClick(res.data.result.sprintProgress);
      });
    },
    //本周榜单
    weeklyBestSellers() {
      getRewardsRank().then((res) => {
        this.studentRankings = res.data.result.rewardsRanks;
        this.peiyoubi = res.data.result.mineTotalRewards;
      });
    },
    changeScroll(type) {
      if (type && this.isTop === 2) return;
      if (!type && this.isTop === 0) return;

      // var parent = document.getElementsByClassName(".classListPyb");
      // var child1 = document.getElementsByClassName("classList");

      // if (parent.scrollTop >= child1.scrollHeight) {
      //   parent.scrollTop = 0;
      // } else {
      //   parent.scrollTop++;
      // }
      // this.isTop = 1;
      // if (type) {
      //   this.scroll += 50;
      //   $(".classListPyb").scrollTop(this.scroll);
      //   let num = $(".classListPyb").scrollTop();
      //   // console.log("this.scroll", this.scroll);
      //   // console.log("num", num);
      //   if (num < this.scroll) {
      //     this.isTop = 2;
      //   }
      // } else {
      //   this.scroll -= 50;
      //   let num = $(".classListPyb").scrollTop();
      //   // console.log("num", num);
      //   // console.log("this.scroll", this.scroll);
      //   $(".classListPyb").scrollTop(this.scroll);
      //   if (num === 0) {
      //     this.isTop = 0;
      //   }
      // }
    },
    studentClass(data) {
      var str = data;
      let a;
      let b;
      var index = str.indexOf("(");
      a = str.slice(3, index); // 3)
      var indexE = a.indexOf(")");
      b = a.slice(0, indexE);
      return b;
    },
    countTime() {
      // 获取当前时间
      let date = new Date();
      let now = date.getTime();
      // 设置截止时间
      let endDate = new Date(this.curStartTime); // this.curStartTime需要倒计时的日期
      let end = endDate.getTime();
      // 时间差
      let leftTime = end - now;
      // 定义变量 d,h,m,s保存倒计时的时间
      if (leftTime >= 0) {
        this.day = Math.floor(leftTime / 1000 / 60 / 60 / 24);
        let h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
        this.hour = h < 10 ? "0" + h : h;
        let m = Math.floor((leftTime / 1000 / 60) % 60);
        this.min = m < 10 ? "0" + m : m;
      } else {
        this.day = 0;
        this.hour = "00";
        this.min = "00";
      }
      // 等于0的时候不调用
      if (
        Number(this.hour) === 0 &&
        Number(this.day) === 0 &&
        Number(this.min) === 0
      ) {
        return;
      } else {
        // 递归每分调用countTime方法，显示动态时间效果,
        setTimeout(this.countTime, 6000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  color: #666;
  height: 100%;
  font-size: 1.1429rem;
  .bv-example-row {
    position: relative;
    height: 100%;
    padding: 6vw 5vw;
    .home-top {
      height: 100%;
      .home-top-left {
        display: flex;
        align-items: center;
        justify-content: center;
        .btn-secondary {
          cursor: pointer;
          padding: 1vw 0;
          display: block;
          margin: 0 auto;
          font-size: 1.1429rem;
          .icon-xinbaniconshangchuan- {
            font-size: 1.3vw;
          }
          .icon-xingzhuangjiehe {
            font-size: 1.1vw;
          }
          .icon-biaoqiankuozhan_bangdan-348 {
            font-size: 1.5vw;
          }
        }
      }
      .all-pass-img {
        width: 100%;
        img {
          position: absolute;
          right: 0;
          top: 40rem;
          width: 8%;
        }
      }
      .all-pass-text {
        // height: 0;
        position: absolute;
        top: 52rem;
        right: 13vw;
        color: #f0f0f0;
        padding: 1.1vw 0.8vw;
        font-size: 0.73vw;
        background: linear-gradient(
          0deg,
          #2cb774 0%,
          #0ccd70 56.00000000000001%
        );
        box-shadow: 0px 8px 1px 0px rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        z-index: 999;
        transition: opacity 0.5s;
      }
      .all-pass-text:after {
        width: 0px;
        height: 2px;
        border-width: 22px;
        border-style: solid;
        border-color: transparent;
        margin-left: 0px;
        color: #0ccd70;
        top: 12px;
        left: 100%;
        content: "";
        position: absolute;
        border-left: 30px solid;
        border-top: 9px solid transparent;
        border-bottom: 11px solid transparent;
      }
      .all-pass-text {
        -moz-animation: cssAnimation 0s ease-in 5s forwards;
        /* Firefox */
        -webkit-animation: cssAnimation 0s ease-in 5s forwards;
        /* Safari and Chrome */
        -o-animation: cssAnimation 0s ease-in 5s forwards;
        /* Opera */
        animation: cssAnimation 0s ease-in 5s forwards;
        -webkit-animation-fill-mode: forwards;
        animation-fill-mode: forwards;
      }
      @keyframes cssAnimation {
        to {
          width: 0;
          height: 0;
          overflow: hidden;
          padding: 0;
        }
      }
      @-webkit-keyframes cssAnimation {
        to {
          width: 0;
          height: 0;
          visibility: hidden;
        }
      }
    }
    .home-bottom {
      width: 90%;
      position: absolute;
      bottom: 3vw;
      font-size: 1vw;
      .bottom-digital {
        font-weight: bold;
        color: $text-green;
      }
      .pyb-digital {
        text-decoration: underline;
        cursor: pointer;
      }
      .home-bottom-countdown {
        font-size: 1vw;
      }
      .home-bottom-time {
        span {
          font-size: 1.428rem;
          font-weight: bold;
          color: $text-yellow;
        }
      }
      .icon-w_jinbi,
      .icon-dianzan {
        font-size: 2.142rem;
        color: $text-yellow;
      }
      .home-bottom-quantity {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
<style lang="scss">
.home {
  .home-top-left {
    .btn-secondary {
      border-color: $bg-gray;
      background-color: $bg-gray;
      color: $text-light-gray;
      padding: 0;
    }
    .btn-secondary:not(:disabled):not(.disabled):active {
      border-color: $bg-gray;
      background-color: $bg-gray;
      color: $text-light-gray;
    }
    .btn-secondary:focus,
    .btn-secondary:not(:disabled):not(.disabled):active:focus {
      box-shadow: none;
    }
  }
}
.home-homework-popover {
  top: 1vw !important;
  left: 11vw !important;
  .arrow {
    display: none;
  }
  .popover-header {
    text-align: center;
    background-color: #2cb774;
    color: #fff;
    padding: 0 0.5rem;
    .operation-a {
      border-bottom: 1px solid #5dd69c;
      padding: 1rem 0;
    }
    .operation-task {
      & > div {
        padding: 0.5rem 0;
      }
      .operation-data {
        font-size: 22px;
      }
    }
    .deadline {
      padding: 1rem 0;
      & > div {
        padding: 0.5rem 0;
      }
      & > div:nth-child(1) {
        font-size: 22px;
        font-weight: bold;
      }
    }
  }
}
.home-progress {
  top: 0vw !important;
  left: -6vw !important;
  transition: opacity 0.5s;
  .arrow {
    display: none;
  }
  .popover-header {
    text-align: center;
    background-color: #2cb774;
    color: #fff;
    padding: 0 0.5rem;
    .progress-data {
      padding: 1.5rem 0.5rem;
      .progress-a {
        border-bottom: 1px solid #5dd69c;
        padding: 1rem 0;
        & > div {
          padding: 0.5rem 0;
        }
        .week-data {
          font-size: 22px;
          color: #fbd47f;
          padding-right: 6px;
        }
        .week-text {
          border-left: 1px solid #5dd69c;
        }
      }
      .pyb-quantity {
        padding: 0.5rem 0;
        & > div {
          padding: 0.5rem 0;
        }
        .week-data {
          font-size: 22px;
          color: #fbd47f;
          padding-right: 6px;
        }
        .week-text {
          border-left: 1px solid #5dd69c;
        }
      }
      #progressId {
        width: 225px;
        height: 225px;
      }
    }
  }
}
.home-weekly-list {
  top: -7% !important;
  left: 1% !important;
  transition: opacity 0.5s;
  .popover-header {
    width: 21rem;
    height: 45rem;
    background: #2cb774;
  }
  .arrow {
    display: none;
  }
  .progress-box {
    height: 100%;
    margin-bottom: 30px;
  }
  .listTitle {
    display: flex;
    justify-content: space-between;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    font-family: PingFang SC;
    padding: 6px 4px;
  }
  .classListPyb {
    height: 86%;
    font-size: 16px;
    overflow-y: scroll; /*y轴滚动*/
    text-align: center;
    .classList {
      width: 98%;
      height: 9.8%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #fff;
      border-bottom: 1px solid;
      // @include border-bottom-color("main_border_tottom_color");
      .classListContent {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 5px;
        .classListC {
          width: 46px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: left;
        }
        .classListD {
          width: 35px;
        }
      }
    }
  }
  .classListPyb::-webkit-scrollbar {
    // display: none; /*隐藏滚动条*/
  }
  /*滚动条样式*/
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 6px;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background-color: #fff;
  }
  .box-wrap.el-scrollbar__wrap::-webkit-scrollbar {
    display: none !important;
    /*滚动条整体样式*/
    width: 0px !important;
    height: 0px !important;
  }
  .classA,
  .classB,
  .classC {
    height: 30px;
    width: 20px;
    background-size: 20px;
  }
  .classA {
    background-image: url("../assets/images/icon1.png");
  }
  .classB {
    background-image: url("../assets/images/icon2.png");
  }
  .classC {
    background-image: url("../assets/images/icon3.png");
  }
  .yellowBackground {
    width: 21px;
    height: 21px;
    background-color: #ffc13b;
    border-radius: 50%;
  }
  .personal-information {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 13px;
  }
  .upDownArrow {
    span {
      color: #fff;
      padding: 0 10px;
    }
  }
  .arrowGrey {
    opacity: 0.4;
  }
  .arrowWhite {
    color: #fff;
  }
  .myPyb {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 16px;
    padding-top: 7px;
    span:nth-child(2) {
      font-size: 18px;
      font-weight: bold;
      padding-right: 2px;
    }
  }
}
.click-button {
  width: 6.5rem;
  height: 6.885rem;
  background-color: #2cb774 !important;
  color: #fff !important;
}
.pyb-dialog .el-dialog__body {
  padding: 0;
  border-radius: 10px 10px 0 0;
}
.pyb-dialog .el-dialog__header {
  padding: 0;
  border-radius: 10px 10px 0 0;
}
.pyb-dialog {
  border-radius: 10px;
  .el-dialog__headerbtn {
    z-index: 999;
  }
  .el-icon-close:before {
    color: #e5e5e5;
  }
}
</style>
