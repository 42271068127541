<template>
  <b-container class="home-module" fluid>
    <b-row>
      <b-col
        class="module"
        v-for="(ModuleCard, index) in studenModule"
        :key="index"
        sm="6"
        md="3"
        cols="6"
      >
        <div class="module-card">
          <img
            class="module-img"
            :src="ModuleImg(ModuleCard.sprintId).src"
            alt=""
          />
          <img
            class="module-people"
            :src="ModuleImg(ModuleCard.sprintId).url"
            alt=""
          />
          <img
            class="module-test"
            :src="ModuleImg(ModuleCard.sprintId).test"
            alt=""
          />
          <div class="card-information">
            <div>
              <span class="open-module">{{
                getModuleName(ModuleCard.sprintId)
              }}</span>
            </div>
            <div>
              <span class="iconfont icon-u-wo"></span>
              <span class="card-total-people"
                >{{ ModuleCard.studentCount }}人</span
              >
              <span class="iconfont icon-fenxi"></span>
              <span>{{ ModuleCard.percent | turnPercentage }}%</span>
            </div>
            <div class="begin-but">
              <!-- v-show="getStepState(ModuleCard.sprintId, ModuleCard.isPass)" -->
              <b-button
                @click="
                  OpenModule(index, ModuleCard.sprintId, ModuleCard.isPass)
                "
                variant="primary"
                v-show="getStepState(ModuleCard.sprintId, ModuleCard.isPass)"
                :class="ModuleCard.isPass ? 'button-yellow' : 'button-green'"
                >{{ ModuleCard.isPass ? "自由训练" : "开始闯关" }}</b-button
              >
              <span
                v-show="getStepuimage(ModuleCard.sprintId)"
                class="iconfont icon-suo1beifen"
              ></span>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-modal
      v-model="show"
      hide-footer
      hide-backdrop
      :dialog-class="dialogClass"
      content-class="welcome-modal"
      body-class="welcome-body"
    >
      <WelcomeModal
        :activeModuleId="activeModuleId"
        :sprintId="sprintId"
        :name="this.name"
      >
      </WelcomeModal>
    </b-modal>
    <GlobalPopup
      :popupsTitle="`抢滩登陆（大模拟）将在开学后适当时候开放，请耐心等待！`"
      :popupsbuttomA="`取消`"
      :popupsbuttomB="`确定`"
      @OKbutton="
        () => {
          modal1 = false;
        }
      "
      @NObutton="
        () => {
          modal1 = false;
        }
      "
      :isShow="modal1"
    ></GlobalPopup>
  </b-container>
</template>

<script>
import WelcomeModal from "../../components/global-popup/global-welcome-modal";
import GlobalPopup from "@/components/global-popup/global-popup-modal";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      show: false,
      dialogClass: "welcome-dialog",
      activeModuleId: 0,
      moduleContent: "",
      sprintId: "",
      modal1: false,
    };
  },
  components: {
    WelcomeModal,
    GlobalPopup,
  },
  props: {
    studenModule: {
      type: Array,
      default: () => [],
    },
    currentSprintId: {
      default: "1",
    },
    name: {
      default: "",
    },
  },
  watch: {},
  mounted() {},
  methods: {
    ...mapMutations(["setCurrentModuleId"]),
    OpenModule(index, sprintId, isPass) {
      //关闭大模拟临时
      // if(sprintId == 7){
      //   this.modal1 = true
      //   return
      // }
      // 临时打开 济南泉景中学 济南育英中学 培立优内部测试营
      if (
        sprintId == 7 &&
        this.userInfo.schoolName !== "培立优内部测试营" &&
        this.userInfo.schoolName !== "济南泉景中学" &&
        this.userInfo.schoolName !== "济南育英中学"
      ) {
        this.modal1 = true;
        return;
      }
      if (isPass) {
        this.toFreeTestPage(sprintId, isPass);
      } else {
        this.show = true;
      }
      this.activeModuleId = index;
      this.sprintId = sprintId;
      this.setCurrentModuleId(sprintId);
    },
    toFreeTestPage(sprintId, isPass) {
      if (sprintId === 2) {
        this.$router.push({
          name: "SmallModuleFreeList",
          query: {
            // module: 1,
            sprintId: sprintId,
          },
        });
      }
      if (sprintId === 4) {
        this.$router.push({
          name: "BigModuleTrainingFreeBase",
          query: {
            // module: 2,
            sprintId: sprintId,
          },
        });
      }
      if (sprintId === 7) {
        this.$router.push({
          name: "MockBegin",
          query: {
            // module: 3,
            sprintId: sprintId,
            // mode: 'free',
            isPass: isPass,
          },
        });
      }
    },
    ModuleImg(sprintId) {
      let src = "";
      let url = "";
      let test = "";
      switch (sprintId) {
        case 1:
          src = require("../../assets/images/index_pic9.png");
          url = require("../../assets/images/index_pic1.png");
          test = require("../../assets/images/index_pic5.png");
          break;
        case 2:
          src = require("../../assets/images/index_pic10.png");
          url = require("../../assets/images/index_pic2.png");
          test = require("../../assets/images/index_pic6.png");
          break;
        case 4:
          src = require("../../assets/images/index_pic11.png");
          url = require("../../assets/images/index_pic3.png");
          test = require("../../assets/images/index_pic7.png");
          break;
        case 7:
          src = require("../../assets/images/index_pic12.png");
          url = require("../../assets/images/index_pic4.png");
          test = require("../../assets/images/index_pic8.png");
          break;
      }
      return { src: src, url: url, test: test };
    },
    getStepState(sprintId, isPass) {
      console.log("sprintId", sprintId, isPass);
      if (sprintId == 1 && isPass == true) {
        return false;
      } else if (sprintId <= this.currentSprintId) {
        return true;
      }

      if (
        this.currentSprintId <= sprintId &&
        this.currentSprintId == sprintId
      ) {
        return true;
      }
    },
    getStepuimage(sprintId) {
      if (
        this.currentSprintId <= sprintId &&
        this.currentSprintId !== sprintId
      ) {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-module {
  height: 100%;
  letter-spacing: 1px;
  font-size: 1.1429rem;
  .row {
    height: 100%;
    align-items: center;
    .module {
      padding: 0 1.4vw;
    }
    & > .module:nth-child(4) {
      .module-test {
        width: 31%;
      }
    }
    .module-card {
      // height: 23.5vw;
      height: 26vw;
      background: $bg-gray;
      box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.1);
      border-radius: 40px;
      // margin: 0 2.2vw;
      position: relative;
      cursor: pointer;
      padding: 0;
      .open-module {
        font-weight: bold;
      }
      .module-img {
        width: 46%;
        position: absolute;
        top: -3vw;
        right: 0px;
      }
      .module-test {
        width: 38%;
        position: absolute;
        top: 1vw;
        right: 1vw;
      }
      .moduleImgAnimate {
        position: absolute;
        top: -54px;
        right: 0;
        animation: myfirst 7s;
        -webkit-animation: myfirst 5s;
      }
      @keyframes myfirst {
        0% {
          top: -54px;
          right: 0;
        }
        55% {
          right: 0;
          top: -100px;
        }
      }
      .iconfont {
        padding: 0 2px;
        color: #ccc;
        font-size: 1.157rem;
      }
      .card-information {
        width: 100%;
        position: absolute;
        bottom: 1vw;
        text-align: left;
        font-size: 1.05vw;
        padding: 0 1vw;
        & > div {
          padding: 0.1vw 0;
        }
        .card-total-people {
          padding-right: 1.5rem;
        }
      }
      .begin-but {
        text-align: center;
        padding-top: 1rem;
        margin-top: 1.5rem;
        position: relative;
        button {
          // background: linear-gradient(0deg, #0dccd6 0%, #0ae4ef 100%);
          color: $text-white;
          padding: 0.6vw 1.4vw;
          border-radius: 5px;
          box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.1);
          border: none;
        }
        .button-yellow {
          background: linear-gradient(0deg, #ffbe5d 0%, #ffb037 100%);
        }
        .button-green {
          background: linear-gradient(
            0deg,
            #0ccd70 0%,
            #2cb774 56.00000000000001%
          );
        }
        .icon-suo1beifen {
          color: #22b774 !important;
          position: absolute;
          right: 10px;
          top: -34px;
        }
      }
    }
    & > .module:nth-child(1) {
      .open-module {
        color: #fa9575;
      }
      .module-people {
        width: 49%;
        position: absolute;
        top: -3.2vw;
        left: 2.3vw;
      }
      .modulePeopleAnimate {
        width: 45%;
        position: absolute;
        top: -15%;
        left: 44px;
        animation: modulePeopleMyfirst 7s;
        -webkit-animation: modulePeopleMyfirst 5s;
      }
      @keyframes modulePeopleMyfirst {
        0% {
          top: -15%;
          left: 44px;
        }
        55% {
          top: -23%;
          left: 44px;
        }
      }
    }
    & > .module:nth-child(2) {
      .open-module {
        color: #32c2d0;
      }
      .module-people {
        width: 78%;
        position: absolute;
        top: -3vw;
        left: 1vw;
      }
    }
    & > .module:nth-child(3) {
      .open-module {
        color: #f9b85e;
      }
      .module-people {
        width: 49%;
        position: absolute;
        top: -3vw;
        left: 1.6vw;
      }
    }
    & > .module:nth-child(4) {
      .open-module {
        color: #2cb774;
      }
      .module-people {
        width: 93%;
        position: absolute;
        top: -3vw;
        left: 0.5vw;
      }
    }
  }
}
</style>