<template>
  <b-container fluid>
    <b-row :class="welcomeImg(activeModuleId)">
      <b-col class="modal-left" md="4" sm="3" cols="0">
        <img
          src="../../assets/images/index_pic1.png"
          alt=""
          v-if="activeModuleId == 0"
        />
        <img
          v-if="activeModuleId == 1"
          src="../../assets/images/index_pic2.png"
          alt=""
          class="modal-left-imgB"
        />
        <img
          v-if="activeModuleId == 2"
          src="../../assets/images/index_pic3.png"
          alt=""
        />
        <img
          v-if="activeModuleId == 3"
          src="../../assets/images/index_pic4.png"
          alt=""
          class="modal-left-imgD"
        />
      </b-col>
      <b-col class="modal-right" md="7" sm="8">
        <div>
          <div :class="modalRightImg(activeModuleId)">
            <img
              src="../../assets/images/examination-img/examination-pic2.png"
              alt=""
              v-if="activeModuleId == 0"
            />
            <img
              v-if="activeModuleId == 1"
              src="../../assets/images/small-img/small-pic2.png"
              alt=""
            />
            <img
              v-if="activeModuleId == 2"
              src="../../assets/images/big-img/big-pic2.png"
              alt=""
            />
            <img
              v-if="activeModuleId == 3"
              src="../../assets/images/mock-img/mock-pic2.png"
              alt=""
            />
          </div>
          <div
            class="modal-right-content"
            v-html="replaceDynamicText(this.result.text)"
          ></div>
          <div v-if="activeModuleId == 1" class="welcome-vocabulary">
            <div :class="stage !== 4 ? 'begin-back' : 'cancel-back'">
              <div
                class="
                  iconfont
                  icon-yunyingpingtaierjicaidanicon_jichuxinxi_cihuizidian
                "
              ></div>
              <div>词汇</div>
            </div>
            <div :class="stage === 4 ? 'begin-back' : 'cancel-back'">
              <div class="iconfont icon-chaojiyufaxunlian"></div>
              <div>语法与功能</div>
            </div>
          </div>
          <div class="modal-right-button">
            <GlobalButton
              @afterClick="beginSprint"
              buttonType="round"
              class="welcomeButton"
              buttonText="开始闯关"
            ></GlobalButton>
          </div>
          <div class="modal-right-corner">
            <img
              src="../../assets/images/examination-img/examination-pic3.png"
              alt=""
              v-if="activeModuleId == 0"
            />
            <img
              v-if="activeModuleId == 1"
              src="../../assets/images/small-img/small-pic3.png"
              alt=""
            />
            <img
              v-if="activeModuleId == 2"
              src="../../assets/images/big-img/big-pic3.png"
              alt=""
            />
            <img
              v-if="activeModuleId == 3"
              src="../../assets/images/mock-img/mock-pic3.png"
              alt=""
            />
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import GlobalButton from "../global-button/global-button-enter";
import { getWelcome } from "@/api/index";
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      result: "",
      stage: '',
    };
  },
  props: {
    activeModuleId: {
      default: 0,
    },
    sprintId: {
      default: 1,
    },
    isWord: {
      default: false,
    },
    name: {
      default: "",
    },
  },
  components: {
    GlobalButton,
  },
  mounted() {
    this.openWelcome();
  },
  methods: {
    ...mapMutations(["setCurrentModuleId"]),
    replaceDynamicText(text) {
      if (text != null) {
        return text.replace(/studentName/g, this.name);
      }
    },
    openWelcome() {
      getWelcome({ sprintId: this.sprintId }).then((res) => {
        console.log("欢迎页", res);
        this.result = res.data.result;
        if (res.data.result.stage) {
          this.stage = res.data.result.stage;
        }
      });
    },
    welcomeImg(id) {
      console.log("id", id);
      if (id == 0) {
        return "welcomeA";
      }
      if (id == 1) {
        return "welcomeB";
      }
      if (id == 2) {
        return "welcomeC";
      }
      if (id == 3) {
        return "welcomeD";
      }
    },
    modalRightImg(id) {
      console.log("id", id);
      if (id == 0) {
        return "modal-right-imgA";
      }
      if (id == 1) {
        return "modal-right-imgB";
      }
      if (id == 2) {
        return "modal-right-imgC";
      }
      if (id == 3) {
        return "modal-right-imgD";
      }
    },
    beginSprint() {
      console.log('this.stage',this.stage)
      //   if (id == 0) {
      //     // this.moduleImg = "moduleImgAnimate";
      //     // this.modulePeople = "modulePeopleAnimate";
      //     this.dialogClass =
      //       "welcome-dialog";
      //   }
      this.setCurrentModuleId(this.sprintId);
      if (this.activeModuleId == 0) {
        this.$router.push({
          name: "Examination",
          query: {
            // module: 0,
            examId: this.result.examId,
            sprintId: this.sprintId,
            examname: "战备体检(大测评)",
          },
        });
        // this.setCurrentModuleId(this.activeModuleId)
      }
      if (this.activeModuleId == 1) {
        //stage：1 靶向诊断 2.靶向训练 3.效果测评 4.语法
        if (this.stage === 4) {
          this.$router.push({
            name: "SmallModuleGrammarList",
            query: {
              // module: 1,
              examId: this.result.examId,
              sprintId: this.sprintId,
            },
          });
          return;
        }
        if (this.stage === 3) {
          this.$router.push({
            // name: "Small",
            // query: {
            //   module: 1,
            //   examId: this.result.examId,
            //   stage: this.result.stage,
            //   sprintId: this.sprintId,
            //   text: 2,
            // },
            name: "ModuleStartEffectEvaluation",
            query: {
              // module: 1,
            },
          });
          return;
        }
        if (this.stage === 2) {
          this.$router.push({
            name: "SmallModuleWordList",
            query: {
              // module: 1,
            },
          });
          return;
        }
        if (this.stage === 1 || this.stage === 0) {
          this.$router.push({
            name: "Small",
            query: {
              // module: 1,
              examId: this.result.examId,
              sprintId: this.sprintId,
              stage: this.result.stage,
              // text: 1,
            },
          });
        }
      }
      // 大模块  1.靶向诊断 2.靶向训练 3.效果测评
      if (this.activeModuleId == 2) {
        if (this.stage == 1) {
          this.$router.push({
            name: "Big",
            query: {
              // module: 2,
              examId: this.result.examId,
              stage: this.result.stage,
              sprintId: this.sprintId,
            },
          });
        }
        if (this.stage == 2) {
          this.$router.push({
            name: "BigModuleTrainingBase",
            query: {
              // module: 2,
              examId: this.result.examId,
              stage: this.result.stage,
              sprintId: this.sprintId,
            },
          });
        }
        if (this.stage == 3) {
          this.$router.push({
            // name: "BigModuleEffectEvaluation",
            // query: {
            //   module: 2,
            //   examId: this.result.examId,
            //   stage: this.result.stage,
            //   sprintId: this.sprintId,
            // },
            name: "BigModuleStartEffectEvaluation",
            query: {
              // module: 2,
              examId: this.result.examId,
              stage: this.result.stage,
              sprintId: this.sprintId,
            },
          });
        }
      }
      if (this.activeModuleId == 3) {
        this.$router.push({
          name: "Mock",
          query: {
            // module: 3,
            examId: this.result.examId,
            sprintId: this.sprintId,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss">
.welcome-dialog {
  max-width: 81% !important;
  margin-top: 11.2rem !important;
  margin-left: 14% !important;
  letter-spacing: 1px;
  .welcome-modal {
    border: none !important;
    height: 51.3rem;
    border-radius: 19px;
    .modal-header {
      border: none !important;
      position: absolute;
      right: 0;
      z-index: 1041;
    }
  }
  .welcome-body {
    padding: 0 !important;
    .container-fluid {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
    }
    .welcomeA,
    .welcomeB,
    .welcomeC,
    .welcomeD {
      height: 100%;
      margin: 0;
      background-repeat: no-repeat;
      .modal-left {
        position: relative;
        display: flex;
        justify-content: center;
        .modal-left-imgB {
          width: 70%;
        }

        .modal-left-imgD {
          width: 90%;
        }
        img {
          position: absolute;
          top: -50px;
          width: 50%;
        }
      }
      .modal-right {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        & > div {
          width: 100%;
          height: 42rem;
          background-color: $bg-white;
          border-radius: 10px;
          position: relative;
          .modal-right-imgA {
            width: 7vw;
            position: absolute;
            right: 3rem;
            top: -5rem;
            img {
              width: 100%;
            }
          }
          .modal-right-imgB {
            width: 15vw;
            position: absolute;
            right: 3rem;
            top: -3rem;
            img {
              width: 100%;
            }
          }
          .modal-right-imgC {
            width: 13vw;
            position: absolute;
            right: 3rem;
            top: -2.5rem;
            img {
              width: 100%;
            }
          }
          .modal-right-imgD {
            width: 7vw;
            position: absolute;
            right: 5rem;
            top: -2rem;
            img {
              width: 100%;
            }
          }
          .modal-right-content {
            padding: 2vw 0 0 2vw;
          }
          .modal-right-button {
            text-align: center;
            padding-top: 4vw;
            .welcomeButton {
              font-weight: bold;
            }
          }
          .modal-right-corner {
            position: absolute;
            bottom: -3rem;
            right: 3rem;
            width: 15%;
            img {
              width: 100%;
            }
          }
        }
      }
    }
    .welcomeA {
      background-image: url("../../assets/images/examination-img/examination-pic4.png");
    }
    .welcomeB {
      background-image: url("../../assets/images/small-img/small-pic4.png");
    }
    .welcomeC {
      background-image: url("../../assets/images/big-img/big-pic4.png");
    }
    .welcomeD {
      background-image: url("../../assets/images/mock-img/mock-pic4.png");
    }
  }
  .welcome-vocabulary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
    color: $text-white;
    & > div {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 2rem;
      font-size: 1.1429rem;
    }
  }
  .welcome-vocabulary {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
    color: $text-white;
    & > div {
      width: 8rem;
      height: 8rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 2rem;
      font-size: 1.1429rem;
    }
  }
  .begin-back {
    background-color: $bg-yellow;
  }
  .cancel-back {
    background-color: $bg-dark-gray;
  }
}
</style>
