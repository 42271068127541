<template>
  <div class="market">
    <!-- 培优币 -->
    <div v-if="state === 1">
      <div>
        <pyb-home @toshop="toShop"></pyb-home>
      </div>
    </div>
    <!-- 培优商城 -->
    <div v-if="state === 2">
      <div class="shopping-mall">
        <div class="pybhome-img">
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="circle3"></div>
          <div class="circle4"></div>
          <div class="circle5"></div>
          <img src="../../assets/images/pyb.png" alt="" />
        </div>
        <div class="shopping-head">
          <div class="shopping-head-left">
            <div class="head-left-top">
              <div class="iconfont icon-tianchongxing"></div>
              <span>培优商城</span>
            </div>
            <div class="head-left-bottom">
              剩余
              <span>{{ pybtotal}}</span
              >培优币
            </div>
          </div>
          <div class="shopping-head-right">
            <div class="iconfont icon-jiantou_xiangzuoliangci pyb" @click="state = 1">
              <span>培优币</span>
            </div>
            <div class="exchange" @click="gitrewardsHistogram">
              <span>兑换记录</span>
            </div>
          </div>
        </div>
        <div class="shopping-foot">
          <el-table
            :key="2"
            :stripe="true"
            height="100%"
            :data="marketList"
            style="width: 100%"
          >
            <el-table-column
              align="center"
              prop="name"
              label="名称"
              min-width="50%"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="category"
              label="品类"
              min-width="50%"
            ></el-table-column>
            <!-- <el-table-column
              align="center"
              prop="brand"
              label="品牌"
              min-width="50%"
            ></el-table-column> -->
            <el-table-column
              align="center"
              prop=""
              label="详情"
              min-width="70%"
            >
              <template slot-scope="scope">
                <div v-if="scope.row.description.indexOf('img') == -1">
                  {{ scope.row.description }}
                </div>
                <div v-else class="demo-image__preview">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="getImgUrl(scope.row.description)"
                    :preview-src-list="[getImgUrl(scope.row.description)]"
                  >
                  </el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="specification"
              label="规格"
              min-width="60%"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="peiyoubiValue"
              label="培优币"
              min-width="50%"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="exchange"
              label="操作"
              min-width="50%"
            >
              <template slot-scope="scope">
                <el-button
                  class="el-button-text"
                  type="text"
                  size="small"
                  @click="show(scope)"
                  >兑换</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <!-- 兑换记录 -->
    <div v-if="state === 3">
      <div class="records">
        <div class="pybhome-img">
          <div class="circle1"></div>
          <div class="circle2"></div>
          <div class="circle3"></div>
          <div class="circle4"></div>
          <div class="circle5"></div>
          <img src="../../assets/images/pyb.png" alt="" />
        </div>
        <div class="shopping-head">
          <div class="shopping-head-left">
            <div class="head-left-top">
              <div class="iconfont icon-duihuanqingkuang"></div>
              <span>兑换记录</span>
            </div>
            <div class="head-left-bottom">
              共有
              <span>{{ pybmarketList.length }}</span> 条 兑换记录
            </div>
          </div>
          <div class="shopping-head-right rechedleft">
            <div class="iconfont icon-jiantouarrowheads3 pyb" @click="toShop">
              <span>培优商城</span>
            </div>
          </div>
        </div>
        <div class="shopping-foot">
          <el-table
            :key="3"
            :stripe="true"
            height="100%"
            :data="pybmarketList"
            style="width: 100%"
          >
            <el-table-column align="center" label="时间">
              <template slot-scope="scope">
                {{
                  scope.row.creationTime | dateFormat("YYYY-MM-DD   hh:mm:ss")
                }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="状态">
              <template slot-scope="scope">
                {{
                  scope.row.peiliyouStatusId === 1
                    ? "待确认"
                    : scope.row.peiliyouStatusId === 2
                    ? "已完成"
                    : scope.row.peiliyouStatusId === 8
                    ? "已发货"
                    : ""
                }}
              </template>
            </el-table-column>
            <el-table-column align="center" prop="" label="详情">
              <template slot-scope="scope">
                <div v-if="scope.row.description.indexOf('img') == -1">
                  {{ scope.row.description }}
                </div>
                <div v-else class="demo-image__preview">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="getImgUrl(scope.row.description)"
                    :preview-src-list="[getImgUrl(scope.row.description)]"
                  >
                  </el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="specification"
              label="规格"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="peiyoubi"
              label="培优币"
            ></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div v-show="isShow" class="exchange-page">
      <div class="page-top">
        本次兑换所需优币
        <span>{{ curentRow.peiyoubiValue }}</span> 币
      </div>
      <div class="page-bottom">
        <div class="cancel" @click="hide">
          <span>再想想</span>
        </div>
        <div class="confirm" @click="confirmExchange">
          <span>确认兑换</span>
        </div>
      </div>
    </div>
    <div v-show="success" class="exchange-page success">
      <div class="success-title successful-exchange">
        <span>
          兑换成功！兑换的实物类奖品将被统一派送至学校，请等候通知前往领取；兑换的非实物类奖品，请联系班级群客服获取~
        </span>
        <div>如有任何疑问，请联系培立优客服：400-618-5266</div>
      </div>
      <div class="success-button successful-button" @click="hide">
        <span>知道了</span>
      </div>
    </div>
    <div v-show="fail" class="exchange-page">
      <div class="success-title keep-trying">
        <span>所剩培优币不足</span>
      </div>
      <div class="success-button" @click="hide">
        <span>继续努力</span>
      </div>
    </div>
  </div>
</template>

<script>
import PybHome from "../pyb-dialog/pyb-home.vue";
// import { getRewardsOverviewForView } from "@/api/index";
export default {
  name: "PybShop",
  components: {
    PybHome,
  },
  data() {
    return {
      state: 1,
      pybtotal: "",
      marketList: [],
      pybmarketList: [],
      isShow: false,
      curentRow: {},
      success: false,
      fail: false,
    };
  },
  methods: {
    show({ row }) {
      this.curentRow = row;
      this.isShow = true;
      console.log("peiyoubiROw", this.curentRow);
    },
    hide() {
      this.isShow = false;
      this.success = false;
      this.fail = false;
    },
    // 培优币商城数据
    toShop() {
      this.state = 2;
      this.pybCentroCommerciale();
    },
    pybCentroCommerciale() {
      //   let parameter = {
      //     type: 2
      //   };
    //   getRewardsOverviewForView().then((res) => {
    //     // debugger
    //     console.log("培优币商城", res);
    //     this.pybtotal = res.result.amount;
    //     this.marketList = res.result.shopItems;
    //     console.log("列表", this.marketList);
    //   });
    },
    // 培优币兑换记录
    gitrewardsHistogram() {
      //   this.state = 3;
      //   let parameter = {
      //     userId: this.$store.state.studentId
      //   };
      //   getRedeemRecord(parameter).then(res => {
      //     console.log("培优币兑换记录", res);
      //     this.pybmarketList = res.result.redeemRecords;
      //   });
    },
    // 确认兑换按钮触发事件
    confirmExchange() {
      //   const { peiyoubiValue: price, id: productId } = this.curentRow;
      //   if (this.pybtotal < price) {
      //     this.fail = true;
      //     return;
      //   } else {
      //     this.success = true;
      //   }
      //   let parameter = {
      //     price,
      //     productId
      //   };
      //   redeem(parameter).then(res => {
      //     console.log("点击培优币兑换按钮", res);
      //   });
      //   this.pybCentroCommerciale();
    },
    getImgUrl(html) {
      //   debugger;
      //   let srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
      //   let str = replaceImgUrl(html);
      //   let src = str.match(srcReg)[1].replace("src=", "");
      return html;
    },
  },
};
</script>

<style scoped lang="scss">
//培优商城
.market {
  position: relative;
}
.pybhome-img {
  display: flex;
  align-items: center;
  img {
    position: absolute;
    top: -14%;
    left: 40%;
  }
  .circle1,
  .circle2,
  .circle3,
  .circle4,
  .circle5 {
    width: 100px;
    height: 50px;
    border-radius: 50px 50px 0 0;
    line-height: 50px;
    background-color: #2cb774;
    opacity: 0.73;
    position: absolute;
  }
  .circle1 {
    top: -30px;
    left: 24%;
  }
  .circle2 {
    top: -25px;
    left: 33%;
  }
  .circle3 {
    top: -22px;
    left: 51%;
  }
  .circle4 {
    top: -40px;
    left: 75%;
  }
  .circle5 {
    background: #fecc42;
    top: -50px;
    left: 82%;
  }
}
.shopping-foot {
  height: 350px;
}
.shopping-head {
  background-color: #2cb774;
  border-radius: 10px 10px 0 0;
  display: flex;
}
.shopping-head-left {
  padding: 22px 0 22px 19px;
  width: 50%;
}
.head-left-top {
  display: flex;
  padding-bottom: 27px;
  align-items: center;
  div {
    font-size: 27px;
    color: #fecc42;
    padding-right: 10px;
  }
  span {
    font-size: 20px;
    color: #fff;
    font-weight: 800;
  }
}
.head-left-bottom {
  display: flex;
  text-align: left;
  color: #fff;
  font-size: 18px;
  span {
    font-size: 20px;
    font-weight: 800;
    padding-left: 5px;
    padding-right: 5px;
  }
}
//商城按钮
.shopping-head-right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  div {
    border: 1px solid #fff;
    margin-left: 15px;
    border-radius: 30px;
    color: #fff;
  }
}
.exchange {
  font-size: 16px;
  padding: 5px 20px 5px 17px;
  cursor: pointer;
}
.pyb {
  padding: 5px 20px 5px 17px;
  cursor: pointer;

  span {
    padding-left: 3px;
  }
}

//兑换记录
.rechedleft {
  display: flex;
  justify-content: flex-end;
  margin-right: 80px;
}
//兑换
.exchange-page {
  @include background_color("assist_main_back");
  float: left;
  width: 367px;
  height: 165px;
  border-radius: 10px;
  position: absolute;
  top: 200px;
  left: 270px;
}
.page-top {
  display: flex;
  justify-content: center;
  padding-top: 47px;
  font-size: 18px;
  color: #fff;
  span {
    color: #fed852;
    font-weight: 800;
  }
}
.page-bottom {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  div {
    border-radius: 16px;
    width: 105px;
    height: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 18px;
    }
  }
}
.cancel {
  background-color: $bg-white;
  margin-right: 35px;
  cursor: pointer;
}
.confirm {
  background-color: $bg-yellow;
  color: #fff;
  cursor: pointer;
}
.success-title {
  padding-top: 30px;
  color: #fff;
  font-size: 18px;
  div {
    padding: 10px 0px;
  }
}
.successful-exchange {
  padding-top: 20px;
  font-size: 15px;
  div {
    padding: 10px 0;
  }
}
.success-button {
  padding-top: 20px;
  font-size: 18px;
  span {
    background-color: #fdd751;
    color: #fff;
    padding: 4px 22px;
    border-radius: 30px;
  }
}
.successful-button {
  padding-top: 8px;
  font-size: 16px;
  span {
    padding: 2px 14px;
  }
}
.keep-trying {
  padding: 50px 0px 20px 0px;
}
.el-button-text {
  @include font_color("main_button_text");
}
</style>
<style lang="scss">
.shopping-foot {
  .el-table .cell img {
    width: 100%;
    height: 100%;
  }
}
</style>
